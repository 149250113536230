import {FC, memo} from 'react';

import Icon, {IconProps} from './Icon';

const FacebookIcon: FC<IconProps> = memo(props => (
  <Icon viewBox="0 0 128 128" {...props}>
    <path
      d="M116.42 5.07H11.58a6.5 6.5 0 00-6.5 6.5v104.85a6.5 6.5 0 006.5 6.5H68V77.29H52.66V59.5H68V46.38c0-15.22 9.3-23.51 22.88-23.51a126 126 0 0113.72.7v15.91h-9.39c-7.39 0-8.82 3.51-8.82 8.66V59.5H104l-2.29 17.79H86.39v45.64h30a6.51 6.51 0 006.5-6.5V11.58a6.5 6.5 0 00-6.47-6.51z"
      fill="currentColor"></path>
  </Icon>
));

export default FacebookIcon;
